import { CPTcodeCategoryEnum } from '../../Enum/CPTcodeCategoryEnum';
import BaseEntity from '../BaseEntity.model';

export default interface ICPTCodeGroup extends BaseEntity {
    GroupName: string;
    CPTRoleCodes: CPTRoleCode[];
}

export class CPTcode {
    Id: string;
    Code: string;
    Description: string;
    CPTcodeCategory: CPTcodeCategoryEnum;
    MedicareRate: number;
    MedicaidRate: number;
    IsDeleted: boolean;
    Name: string;
}

export class CPTRoleCode {
    Role: string;
    Codes: CPTcode[];
}

export class CPTCodeGroup implements ICPTCodeGroup {
    Id: string;
    GroupName: string;
    CPTRoleCodes: CPTRoleCode[];

    constructor() {
        this.GroupName = '';
        this.CPTRoleCodes = [] as CPTRoleCode[];
    }
}
