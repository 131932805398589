import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import { DataGrid, gridClasses, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllCPTCodes, insertOrUpdateCodeGroup, setSelectedIds } from '../../store/adminCPTCodesSlice.slice';
import { RootState } from '../../reducers';
import { CPTcodeCategoryEnum } from '../../Enum/CPTcodeCategoryEnum';
import { copyObject } from '../../utils/common';

const EditRoleCodesModal = (props: any) => {
    const dispatch = useDispatch();
    const { activeCptCodes, selectedGroup, selectedIds, selectedRole } = useSelector((state: RootState) => state.adminCPTCodesSlice);

    const columns: GridColDef[] = [
        {
            field: 'Code',
            headerName: 'Code',
            minWidth: 120,
        },
        {
            field: 'CPTcodeCategory',
            headerName: 'CPT Code Category',
            minWidth: 160,
            valueGetter: (params: GridValueGetterParams) => `${CPTcodeCategoryEnum[params.row.CPTcodeCategory]}`,
        },
        {
            field: 'Description',
            headerName: 'Description',
            minWidth: 160,
            flex: 1,
        },
        {
            field: 'MedicareRate',
            headerName: 'MedicareRate',
            minWidth: 120,
            valueGetter: (params: GridValueGetterParams) => `$${params.row.MedicareRate.toFixed(2)}`,
        },
        {
            field: 'MedicaidRate',
            headerName: 'MedicaidRate',
            minWidth: 120,
            valueGetter: (params: GridValueGetterParams) => `$${params.row.MedicaidRate.toFixed(2)}`,
        },
    ];

    useEffect(() => {
        dispatch(fetchAllCPTCodes());
    }, [dispatch]);

    const handleSubmit = () => {
        const selectedCPTCodes = activeCptCodes.filter((cptCode) => selectedIds.includes(cptCode.Id));
        const selectedGroupCopy = copyObject(selectedGroup);
        const role = selectedGroupCopy.CPTRoleCodes.find((role) => role.Role === selectedRole);
        role.Codes = [...selectedCPTCodes];
        dispatch(insertOrUpdateCodeGroup(selectedGroupCopy));
        props.onClose();
    };

    return (
        <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="lg">
            <DialogTitle>Add/Remove CPT Codes</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12} height={'75vh'}>
                        <DataGrid
                            rows={activeCptCodes.filter((cptCode) => !cptCode.IsDeleted)}
                            columns={columns}
                            checkboxSelection
                            pageSize={10}
                            onSelectionModelChange={(newRowSelectionModel) => {
                                dispatch(setSelectedIds(newRowSelectionModel));
                            }}
                            selectionModel={selectedIds}
                            rowsPerPageOptions={[]}
                            getRowId={(row) => row.Id}
                            getRowHeight={() => 'auto'}
                            sx={{
                                [`& .${gridClasses.cell}`]: {
                                    py: 1,
                                },
                            }}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions sx={{ p: 2 }}>
                <Button variant="outlined" sx={{ minWidth: '120px' }} onClick={props.onClose}>
                    Cancel
                </Button>
                <Button variant="contained" sx={{ minWidth: '120px' }} onClick={handleSubmit}>
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EditRoleCodesModal;
